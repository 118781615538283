import { Link, useLocation } from "react-router-dom";

import forestPath from "../../assets/images/forest-path.png";

export default function Company() {
  const { state } = useLocation();
  return (
    <div className="about company">
      <div className="flex-container back">
        <Link to={ (state && state.previousPath) || '/about' } className="btn outline back">
          &lt;&lt; Back
        </Link>
        <h1>About the Company</h1>
      </div>
      <p>
        Over the years, I have seen how many with the desire to improve their
        emotional health are often limited by the high cost, both financially
        and time wise, of traditional therapy. Others are worried about the
        impression it would give speaking to someone who might be quick to
        misrepresent or misunderstand their spiritual or religious background. I
        wanted to see if I could offer something that would provide a
        steppingstone or bridge between those concerns and getting help.
      </p>
      <p>
        As I was completing my CBT practitioners’ course, I began offering
        "practice sessions" to some brave friends who had at times expressed the
        desire to get help but also had some of the concerns I mentioned above.
      </p>
      <img src={forestPath} alt="forest path" />
      <p>
        The response was incredibly touching and extremely encouraging. Even
        after just a few sessions, my services were being recommended to others
        and I soon realized this was a need I could fill! While some may still
        need the help of a licensed therapist or specialist, many simply need a
        little coaching to identify the core beliefs, limiting thoughts and
        ingrained habits which have been robbing them of peace.
      </p>
      <p>
        That's when I realized that New Pathways Coaching wasn’t just possible
        but was also something I was really excited about. I love getting to see
        how people go from timid to empowered, burning out to self-nurturing,
        overwhelmed to informed!
      </p>
      <p>
        I began laying out a path for potential clients to follow which would
        address common problems at a manageable pace and in a way that I hope
        will be memorable for lasting change. That path helps them to create new
        patterns of thinking - new pathways of thought, new connections. One’s
        they have chosen for themselves based on what they learn.
      </p>
      <p>By January of 2024 it was ready to go live.</p>
      <p>
        The program I created is not endless, nor unnecessarily painful. But it
        is work. However, I truly believe that if a person can put in the time
        and effort now, they will only need to do it once! Future you will thank
        you!
      </p>
      <div className="flex-container control-panel">
        <h2>Ready to test it out? Let's schedule a free consultation.</h2>
        <Link to="/contact" className="btn emphasis">
          Schedule Now &gt;
        </Link>
      </div>
    </div>
  );
}
